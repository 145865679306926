







import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const axiosInstance = root.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;
    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    const state = reactive({
      loading: false,
      unread: 0,
    });

    const fetchAlerts = () => {
      if (hasAccessTo("admin")) {
        state.loading = true;

        axiosInstance
          .get("alert", {
            params: {
              page: 1,
              itemsPerPage: 1,
              unread: true,
              sortBy: ["createdAt"],
              sortDesc: [true],
            },
          })
          .then(({ data }) => {
            state.unread = data.total;
          })
          .catch(() => {
            state.unread = 0;
          })
          .finally(() => (state.loading = false));
      }
    };

    onMounted(fetchAlerts);
    watch(() => root.$route.fullPath, fetchAlerts);

    return { hasAccessTo, state, fetchAlerts };
  },
});
